<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div
      class="modal fade"
      id="modal-form-reporte-nacionales"
      style="background-color: #00000082"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">
              Generar Reporte Solicitudes Nacionales
            </h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-0 pt-1">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="tab-Filtros"
                  data-toggle="tab"
                  href="#Filtros"
                  >Filtros
                </a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade active show" id="Filtros">
                <div class="card-body">
                  <div class="row">
                    <div class="form-group col-md-12">
                      <label for="">Empresa</label>
                      <v-select
                        :class="[
                          $store.getters.getDarkMode ? 'dark-vselect' : '',
                        ]"
                        v-model="empresa"
                        placeholder="Empresas"
                        label="razon_social"
                        class="p-0 col-md-12"
                        :options="listasForms.empresas"
                        @input="selectEmpresa()"
                        @search="buscarEmpresas"
                      ></v-select>
                    </div>
                    <div class="form-group col-md-6">
                      <label for="">Fecha Inicial</label>
                      <input
                        type="date"
                        class="form-control form-control-sm"
                        v-model="filtros.fecha_inicial"
                        :class="
                          $v.filtros.fecha_inicial.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        @input="validaFechas"
                      />
                    </div>
                    <div class="form-group col-md-6">
                      <label for="">Fecha Final</label>
                      <input
                        type="date"
                        class="form-control form-control-sm"
                        v-model="filtros.fecha_final"
                        :class="
                          $v.filtros.fecha_final.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        @input="validaFechas"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <div>
              <button
                type="button"
                class="btn btn-sm bg-navy"
                @click="generarReporte()"
                v-if="
                  $store.getters.can('tep.reportes.exportNacionales') &&
                    !$v.filtros.$invalid
                "
              >
                Generar Reporte
                <i class="fas fa-file-download"> </i>
              </button>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import axios from "axios";
import Loading from "../../../../components/Loading";
import vSelect from "vue-select";

export default {
  name: "ReporteNacionales",
  components: {
    Loading,
    vSelect,
  },
  data() {
    return {
      cargando: false,
      empresa: [],
      filtros: {
        fecha_inicial: null,
        fecha_final: null,
        empresa_id: null,
      },
      listasForms: {
        empresas: [],
      },
    };
  },
  validations: {
    filtros: {
      fecha_inicial: {
        required,
      },
      fecha_final: {
        required,
      },
    },
  },

  methods: {
    async buscarEmpresas() {
      let me = this;
      me.listasForms.empresas = [];
      var url = "api/admin/empresas/lista?estado=1";
      await axios
        .get(url, {
          params: {
            linea_negocio_id: [5],
          },
        })
        .then(function(response) {
          me.listasForms.empresas = response.data;
        })
        .catch(function(e) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    selectEmpresa() {
      this.filtros.empresa_id = null;
      if (this.empresa) {
        this.filtros.empresa_id = this.empresa.id;
      }
    },

    validaFechas() {
      if (this.filtros.fecha_inicial && this.filtros.fecha_final) {
        var fecha_menor = new Date(this.filtros.fecha_inicial);
        var fecha_mayor = new Date(this.filtros.fecha_final);
        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.filtros.fecha_final = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicial no puede ser mayor a la fecha final...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
      if (this.filtros.fecha_inicial && this.filtros.fecha_final) {
        fecha_menor = new Date(this.filtros.fecha_inicial).getTime();
        fecha_mayor = new Date(this.filtros.fecha_final).getTime();
        var diff = fecha_mayor - fecha_menor;
        var fecha_limite = diff / (1000 * 60 * 60 * 24);
        if (fecha_limite > 31) {
          this.filtros.fecha_final = null;
          this.$swal({
            icon: "error",
            title: `Las fechas no pueden ser mayor a 31 dias...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    limpiarModal() {
      this.empresa = [];
      this.filtros = {
        empresa_id: null,
        fecha_inicial: null,
        fecha_final: null,
      };
    },

    generarReporte() {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/tep/solicitudesTransporte/reporteNacionalExport",
        data: { form: this.form, filtros: this.filtros },
      })
        .then((response) => {
          this.cargando = false;
          let data = response.data;
          if (!response.data.error) {
            location.href = data.url;
            this.$swal({
              icon: "success",
              title: response.data.message,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          } else {
            this.$swal({
              icon: "error",
              title: response.data.error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },
  async mounted() {
    this.cargando = true;
    this.cargando = false;
  },
};
</script>
